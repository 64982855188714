import React, { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import '@splidejs/react-splide/css/core';

import styles from './commentForm.module.scss'
import imgNoAvatar from '../../assets/images/general/noAvatar.jpg'
import { ArrowGallery } from "../../assets/images/svg/svg";
import Modal from "../Modal/modal";
import { addComment } from "../../assets/api/apiCall";

function CommentForm({ isModalOpened, setIsModalOpened, setShow, myTimeOutArray, setMyTimeOutArray }) {
    const [image, setImage] = useState(null)
    const coverRef = useRef()
    const loaderRefBig = useRef()
    const commentImgRef = useRef()
    const commentImgRef2 = useRef()

    function sendComment(event) {
        event.preventDefault()

        const imageInput = event.target[0];
        const authorInput = event.target[1];
        const textInput = event.target[2]

        if (!authorInput.value || authorInput.value.length < 3) {
            authorInput.classList.add(styles.error)
            setTimeout(() => {
                authorInput.classList.remove(styles.error)
            }, 600)
        } else {
            loaderRefBig.current.classList.add(styles.loaderShow)
            coverRef.current.classList.add(styles.beforeActive)
            setTimeout(() => { coverRef.current.classList.add(styles.active) }, 100)

            setTimeout(() => {
                let data = {
                    author: authorInput.value,
                    text: textInput.value
                }
                addComment(data).then(json => {
                    if (json.success) {


                        myTimeOutArray.forEach((item) => {
                            clearTimeout(item);
                        })
                        setIsModalOpened(false)
                        const myTime = setTimeout(() => { setShow(false); }, 1000)
                        const myTime2 = setTimeout(() => {
                            loaderRefBig.current.classList.remove(styles.loaderShow)
                            coverRef.current.classList.remove(styles.active)
                            coverRef.current.classList.remove(styles.beforeActive)
                            authorInput.value = ''
                            textInput.value = ''
                        }, 300)
                        setMyTimeOutArray([myTime, myTime2])
                    }
                })
            }, 300)
        }

    }

    function imageChange(event) {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setImage(URL.createObjectURL(img))
        }
    };

    function imageRemove() {
        commentImgRef.current.style.opacity = 1
        commentImgRef.current.classList.add(styles.out)
        setTimeout(() => {
            commentImgRef.current.style.opacity = 0
        }, 100)
        setTimeout(() => {
            setImage(null)
        }, 310)
    }

    return (
        <Modal opened={isModalOpened} setOpened={setIsModalOpened} setShow={setShow} myTimeOutArray={myTimeOutArray} setMyTimeOutArray={setMyTimeOutArray}>
            <div className={styles.mainWrapper}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>Dodaj komentarz</div>
                </div>
                <form className={styles.form} onSubmit={event => sendComment(event)}>
                    <div className={`${styles.column} ${styles.columnLeft}`}>
                        {image ?
                            <>
                                <img src={image ? image : imgNoAvatar} className={styles.img} ref={commentImgRef} style={{ opacity: 0 }} />
                                <div onClick={() => imageRemove()} className={`${styles.btn} ${styles.btnBig}`}>Usuń</div>
                            </>
                            :
                            <>
                                <img src={imgNoAvatar} className={styles.imgNoAvatar}/>
                                <label htmlFor={"image"} className={`${styles.btn} ${styles.btnBig}`}>Wybierz zdjęcie</label>
                                <input id={"image"} hidden={true} type={"file"} name={"image"} onChange={event => imageChange(event)}/>
                            </>
                        }
                    </div>
                    <div className={`${styles.column} ${styles.columnRight}`}>
                        <div className={styles.cover} ref={coverRef}>
                            <div className={`${styles.loader} ${styles.loaderBig}`} ref={loaderRefBig} />
                        </div>
                        <div className={styles.row}>
                            <div className={styles.lable}>Autor: </div>
                            <input className={styles.input} placeholder={"min. 3 litery"}></input>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.lable}>Treść: </div>
                            <textarea className={styles.input} rows="5" cols="40" placeholder={"komentarz..."}></textarea>
                        </div>
                        <button className={styles.btn}>Wyślij</button>
                    </div>
                </form>
            </div >
        </Modal >
    )
}

export default CommentForm
import React, { useEffect, useRef, useState } from "react";
import Footer from "../Footer/footer";
import { ArrowLeft } from "../../assets/images/svg/svg";
import ListItem from "../../Components/ListItem/listItem";
import styles from "./author.module.scss";

import authorMainMockup from "../../assets/images/mockup/autorMainMockup.jpg";
import itemMockup from "../../assets/images/mockup/itemMockup.jpg";
import authorMockup from "../../assets/images/mockup/autor.jpg";
import { Loader } from "../../Components/Loader/loader";
import { getAuthor, getAuthorPosts } from "../../assets/api/apiCall";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { authorUrl, only10Words } from "../../Components/Helpers/myUrlHelper";
import scrollTo from "../../Components/Helpers/scrollTo";
import { Helmet } from "react-helmet";

function Author() {
    const [data, setData] = useState(null);
    const [postsData, setPostsData] = useState(null);
    const [posts, setPost] = useState(null);
    const [countBefore, setCountBefore] = useState(0);
    const loaderRef = useRef();
    const shadowRef = useRef();
    const { lang, id } = useParams();
    const idNumber = parseInt(id);

    const handleLoadMore = () => {
        shadowRef.current.classList.add(styles.itemsWrapperShadowShow);
        loaderRef.current.classList.add(styles.loaderShow);

        const urlParams = new URLSearchParams(window.location.search);
        let readPage = parseInt(urlParams.get("page"));
        let page = 1;

        if (readPage > 0) {
            page = parseInt(readPage);
        }
        page++;

        const queryString = window.location.origin + "/" + lang + "/autors/" + authorUrl(data) + `?page=${parseInt(page)}`;
        let search = ``;

        window.history.pushState({}, null, queryString + search);

        setTimeout(() => {
            getAuthorPosts(lang, idNumber, page).then((json) => {
                setPostsData(json);
                json.items.forEach((element) => {
                    setPost([...posts, element]);
                });
                // const newFirstElement = document.getElementById(`searchElement-${posts.length - 1}`)
                // scrollTo(newFirstElement.getBoundingClientRect().bottom + newFirstElement.getBoundingClientRect().height + newFirstElement.getBoundingClientRect().height - newFirstElement.getBoundingClientRect().top, 300)
                setTimeout(() => {
                    if (shadowRef.current) shadowRef.current.classList.remove(styles.itemsWrapperShadowShow);
                    if (loaderRef.current) loaderRef.current.classList.remove(styles.loaderShow);
                }, 400);
            });
        }, 100);
    };

    useEffect(() => {
        if (!data) {
            getAuthor(lang, idNumber).then((json) => {
                if (json) {
                    setData(json);
                    const url = window.location;
                    if (url.pathname.split("/")[3] != authorUrl(json)) {
                        window.history.replaceState({}, null, window.location.origin + "/" + lang + "/autors/" + authorUrl(json) + `${readPage > 1 ? `&page=${readPage}` : ""}`);
                    }
                } else {
                    window.history.replaceState({}, null, window.location.origin + "/" + lang + "/autorzy");
                    document.location.reload();
                }
            });
            console.log(data);
            const urlParams = new URLSearchParams(window.location.search);
            let readPage = parseInt(urlParams.get("page"));
            readPage = readPage ? readPage : 1;
            setTimeout(() => {
                getAuthorPosts(lang, idNumber, readPage, readPage < 2).then((json) => {
                    setPostsData(json);
                    setPost(json.items);
                    setCountBefore(json.items.length);
                });
            }, 100);
        }
    }, [data]);

    return (
        <>
            <Loader data={data} />
            {data ? (
                <>
                    <Helmet>
                        <title>{`Nifc Blog - ${data.name}`}</title>
                        <meta name="description" content={only10Words(data.description)} />
                    </Helmet>
                    <div className={styles.authorWrapper}>
                        <div className={styles.authorContainer}>
                            <div className={styles.authorSections}>
                                <Link to={`/${lang}/autorzy`} className={styles.leftSection}>
                                    <button type={"button"} className={styles.goBack}>
                                        <ArrowLeft />
                                    </button>
                                    <p className={styles.text}>AUTORZY</p>
                                </Link>
                                <div className={styles.rightSection}>
                                    <img src={data?.img_background} alt={data?.name} />
                                    <h1 className={styles.name}>{data?.name}</h1>
                                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: data?.description }} />
                                </div>
                            </div>
                            <div className={styles.articlesWrapper}>
                                <div className={styles.articlesTop}>
                                    <div className={styles.articlesTitle}>Artykuły</div>
                                    <div className={styles.counter}>{postsData?.metaData.totalItems}</div>
                                </div>
                                <div className={styles.itemsWrapper}>
                                    {posts?.map((item, index) => {
                                        if (countBefore > index) return <ListItem id={`searchElement-${index}`} item={item} key={index} animation={false} />;
                                        return <ListItem id={`searchElement-${index}`} item={item} key={index} animation={true} />;
                                    })}
                                    <div className={styles.itemsWrapperShadow} ref={shadowRef} />
                                </div>
                                {postsData?.metaData ? (
                                    posts ? (
                                        postsData.metaData.perPage * postsData.metaData.currentPage < postsData.metaData.totalItems && posts.length < postsData.metaData.totalItems ? (
                                            <>
                                                <div className={styles.loader} ref={loaderRef} />
                                                <button type={"button"} className={styles.loadMore} onClick={handleLoadMore}>
                                                    załaduj kolejne
                                                </button>
                                            </>
                                        ) : null
                                    ) : null
                                ) : null}
                            </div>
                        </div>
                        <Footer />
                    </div>
                </>
            ) : null}
        </>
    );
}

export default Author;

import React, { useEffect, useRef, useState } from "react";

import styles from './searchComponent.module.scss'
import translation from "../Helpers/translation";
import SlideUpDown from "../Helpers/slideUpDown/slideUpDown";
import useWindowSize from "../Helpers/hooks/useWindowSize";
import { getArticle, getArticle2, getCategories } from "../../assets/api/apiCall";
import { useParams } from "react-router-dom";
import scrollTo from "../Helpers/scrollTo";
import { flushSync } from "react-dom";

function SearchComponent({ setData, dataItems, setDataItems, setCountBefore, setIsSearch, shadowRef, loaderRef, midleRef, countBefore, categoryButtonClick, setClosed, closed, categories, openSortRef }) {
    // openSortRef = useRef()
    const searchInputRef = useRef()
    const inputContainer = useRef()
    const [init, setInit] = useState(false);
    const [hideCategory, setHideCategory] = useState(false)
    const size = useWindowSize()
    let { lang } = useParams();

    function categoryButtonClick2(index, value) {
        setClosed(!closed)
        shadowRef.current.classList.add(styles.itemsWrapperShadowShow)
        loaderRef.current.classList.add(styles.loaderShow)

        if (index === 'all') {
            setTimeout(() => {
                setIsSearch(false)
            }, 300)
            openSortRef.current.textContent = size.width > 800 ? translation.site.main.categories.showList : translation.site.main.categories.showListShort
        } else {
            setTimeout(() => {
                setIsSearch(true)
            }, 300)
            openSortRef.current.textContent = value
        }

        const urlParams = new URLSearchParams(window.location.search)
        const queryString = window.location.origin + '/' + lang;
        let readSearch = urlParams.get('search')
        let querySearch = `?lang=${lang}`
        let search = ``

        if (readSearch) {
            search += `?search=${readSearch}&categories=${index}&page=1`
            querySearch += `&search=${readSearch}&categories=${index}&page=1`
        } else {
            search += `?categories=${index}&page=1`
            querySearch += `&categories=${index}&page=1`
        }

        window.history.pushState({}, null, queryString + search);

        //or reload
        // window.location.search = search;

        getArticle2(querySearch).then(json => {
            if (json.items.length == 0) {
                for (let i = 0; i < dataItems.length; i++) {
                    const obj = document.getElementById(`searchElement-${i}`)
                    obj.style.height = obj.scrollHeight + 'px'
                    setTimeout(() => {
                        obj.style.height = 0;
                    }, 100)
                }
            }
            // const obj = document.getElementById(`searchElement-${dataItems.length - 1}`)
            if (dataItems.length > json.items.length) {
                const index = dataItems.length - json.items.length;

                for (let i = dataItems.length - index; i < dataItems.length; i++) {
                    const obj2 = document.getElementById(`searchElement-${i}`)
                    obj2.style.height = obj2.scrollHeight + 'px'
                    setTimeout(() => {
                        obj2.style.height = 0;
                    }, 100)
                }
            }

            setCountBefore(dataItems.length)
            setTimeout(() => {
                setData(json)
                setDataItems(json.items)
                shadowRef.current.classList.remove(styles.itemsWrapperShadowShow)
                loaderRef.current.classList.remove(styles.loaderShow)
            }, 400)

            // setTimeout(() => {
            //     setData(json)
            //     setDataItems(json.items)
            // }, 400)
            // setTimeout(() => {
            //     shadowRef.current.classList.remove(styles.itemsWrapperShadowShow)
            //     loaderRef.current.classList.remove(styles.loaderShow)
            // }, 500);

        })
    }

    function createSearchElement(value) {
        const newText = document.createElement("div")
        newText.classList.add(styles.searchText)
        newText.innerText = value

        const newCross = document.createElement("div")
        newCross.classList.add(styles.searchBtn)
        newCross.addEventListener('click', () => {
            const urlParams = new URLSearchParams(window.location.search)
            let readSearch = urlParams.get('search')
            let readCategories = urlParams.get('categories')

            readSearch = readSearch.split(',')

            const index = readSearch.indexOf(newText.innerText);
            if (index > -1) {
                readSearch.splice(index, 1);
            }

            // readSearch = readSearch.replace(newText.innerText, '')
            // readSearch = readSearch.replace(',,', ',')
            // if (readSearch.slice(0, 1) == ',')
            //     readSearch = readSearch.slice(1, readSearch.length)
            // if (readSearch.slice(-1) == ',')
            //     readSearch = readSearch.slice(0, -1)

            const queryString = window.location.origin + '/' + lang;
            let search = ''
            if (readSearch) {
                search = `?search=${readSearch}`;
                if (readCategories)
                    search += `&categories=${readCategories}`
            } else {
                if (readCategories)
                    search += `?categories=${readCategories}&page=1`
                else
                    search = '?page=1'
            }

            window.history.pushState({}, null, queryString + search);
            newCross.parentElement.remove();
            searchInfo(null, true)
        })

        const newEl = document.createElement("div")
        newEl.classList.add(styles.searchElement)
        newEl.appendChild(newText)
        newEl.appendChild(newCross)

        inputContainer.current.appendChild(newEl)
    }

    function searchInfo(event, reload = false) {
        if (event)
            event.preventDefault();
        setHideCategory(false)
        const value = searchInputRef.current.value.trim()
        if (value || reload == true) {
            if (value)
                searchInputRef.current.readOnly = true;

            shadowRef.current.classList.add(styles.itemsWrapperShadowShow)
            loaderRef.current.classList.add(styles.loaderShow)
            scrollTo(midleRef.current.offsetTop, 1000)

            const urlParams = new URLSearchParams(window.location.search)
            let readSearch = urlParams.get('search')
            let readCategories = urlParams.get('categories')

            const queryString = window.location.origin + '/' + lang;
            let querySearch = null;
            let search = null;

            if (readSearch) {
                setTimeout(() => {
                    setIsSearch(true)
                }, 300)

                if (reload) {
                    querySearch = `?lang=${lang}&search=${readSearch}`
                    search = `?search=${readSearch}`
                    if (readCategories) {
                        querySearch += `&categories=${readCategories}`
                        search += `&categories=${readCategories}&page=1`
                    } else {
                        search += '&page=1'
                    }
                } else {
                    querySearch = `?lang=${lang}&search=${readSearch},${value}`
                    search = `?search=${readSearch},${value}`
                    if (readCategories) {
                        querySearch += `&categories=${readCategories}`
                        search += `&categories=${readCategories}&page=1`
                    } else {
                        search += '&page=1'
                    }
                }
            } else {

                if (reload) {
                    setTimeout(() => {
                        setIsSearch(false)
                    }, 300)
                    querySearch = `?lang=${lang}`
                    search = ``
                    if (readCategories) {
                        querySearch += `&categories=${readCategories}`
                        search += `?categories=${readCategories}&page=1`
                    } else {
                        search += '?page=1'
                    }
                } else {
                    setTimeout(() => {
                        setIsSearch(true)
                    }, 300)
                    querySearch = `?lang=${lang}&search=${value}`
                    search = `?search=${value}`
                    if (readCategories) {
                        querySearch += `&categories=${readCategories}`
                        search += `&categories=${readCategories}&page=1`
                    } else {
                        search += '&page=1'
                    }

                }
            }

            window.history.pushState({}, null, queryString + search);

            setTimeout(() => {
                getArticle2(querySearch).then(json => {

                    const dates = document.querySelectorAll('[id^="searchElement"]');
                    setCountBefore(dates.length)

                    if (json.items.length == 0) {
                        for (let i = 0; i < dataItems.length; i++) {
                            const obj = document.getElementById(`searchElement-${i}`)
                            obj.style.height = obj.scrollHeight + 'px'
                            setTimeout(() => {
                                obj.style.height = 0;
                            }, 100)
                        }
                    }

                    if (dates.length > json.items.length) {
                        for (let i = json.items.length; i < dates.length; i++) {
                            const obj2 = document.getElementById(`searchElement-${i}`)
                            if (obj2) {
                                obj2.style.height = obj2.scrollHeight + 'px'
                                setTimeout(() => {
                                    obj2.style.height = 0;
                                }, 100)
                            }
                        }
                    }

                    setTimeout(() => {
                        setData(json)
                        setDataItems(json.items)


                        shadowRef.current.classList.remove(styles.itemsWrapperShadowShow)
                        loaderRef.current.classList.remove(styles.loaderShow)

                        if (!reload) {
                            createSearchElement(value)
                        }
                        searchInputRef.current.value = ''
                        searchInputRef.current.readOnly = false;
                    }, 400)

                    // setData(json)
                    // setDataItems(json.items)
                    // setCountBefore(json.items.length)
                    // shadowRef.current.classList.remove(styles.itemsWrapperShadowShow)




                })
            }, 500)
        }
    }

    // useEffect(() => {
    //     if (!init) {
    //         const urlParams = new URLSearchParams(window.location.search)
    //         let readCategories = urlParams.get('categories')
    //         let readSearch = urlParams.get('search')

    //         getCategories(lang).then(json => {
    //             setCategories(json)
    //             json.forEach(element => {
    //                 if (element.id == readCategories)
    //                     openSortRef.current.textContent = element.name
    //             });
    //         })

    //         // if (readSearch) {
    //         //     const searchArray = readSearch.split(',')
    //         //     searchArray.forEach((element) => {
    //         //         createSearchElement(element)
    //         //     });
    //         // }
    //         setInit(true)
    //     }
    // }, [lang, init])

    // useEffect(() => {
    //     console.log("CAT :D ", window.location)
    //     if (categories) {
           
    //         const urlParams = new URLSearchParams(window.location.search)
    //         const queryString = window.location.origin + '/' + lang;
    //         let readCategories = urlParams.get('categories')
    //         let readPage = urlParams.get('page')
    //         let readSearch = urlParams.get('search')
    //         if (!readSearch && readCategories && readPage) {
    //             categoryButtonClick(readCategories,)
    //         }
    //     }

    // }, [window.location.href])

    return (
        <div className={styles.searchComponentWrapper}>
            <form onSubmit={event => searchInfo(event)} className={styles.left} onFocus={() => setHideCategory(true)} onBlur={() => setHideCategory(false)}>
                <div className={styles.searchIcon} />
                <div className={styles.inputContainer} ref={inputContainer}></div>
                <input className={`${styles.inputText} ${hideCategory ? styles.inputTextExpand : ''}`} placeholder={translation.site.main.search} ref={searchInputRef} />
                <button type={'button'} className={`${styles.searchButton} ${closed ? '' : styles.opened}`} onBlur={() => openSortRef.current.focus()} onClick={(event) => searchInfo(event)}>{translation.site.main.search}</button>
            </form>
            <div className={`${styles.right} ${hideCategory ? styles.rightHide : ''}`}>
                <div className={styles.container}>
                    <button id="openSort" type={'button'} ref={openSortRef} className={`${styles.openSort} ${closed ? styles.closed : ''}`}
                        onClick={() => {
                            setClosed(!closed)
                            setTimeout(() => {
                                document.getElementsByClassName(styles.option)[0].focus()
                            }, 200)
                        }}
                    >{size.width > 800 ? translation.site.main.categories.showList : translation.site.main.categories.showListShort}</button>
                    <div className={styles.sortOptionsContainer}>
                        <SlideUpDown closed={closed} closedStart={true}>
                            <div className={styles.optionsContainer}>
                                <div className={styles.optionsWrapper}>
                                    <button
                                        type={"button"}
                                        className={`${styles.option} ${closed ? styles.optionArrowRotate : ''}`}
                                        onClick={() => categoryButtonClick('all', null, true)}
                                        onBlur={() => setClosed(true)}
                                        onFocus={() => setClosed(false)}
                                    >
                                        {size.width > 800 ? translation.site.main.categories.showList : translation.site.main.categories.showListShort}
                                        <div className={styles.arrowBlack} />
                                        <div className={styles.arrowWhite} />
                                    </button>
                                    {categories ? categories.map((item, index) => {
                                        return (
                                            <button
                                                key={index}
                                                type={"button"}
                                                className={styles.option}
                                                onClick={() => categoryButtonClick(item.id, item.name, true)}
                                                onBlur={() => setClosed(true)}
                                                onFocus={() => setClosed(false)}
                                            >
                                                {item.name}
                                            </button>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </SlideUpDown>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchComponent